import {
    applyMiddleware,
    compose,
    legacy_createStore as createStore,
} from 'redux';
import thunk from 'redux-thunk';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { reducers } from './../reducers';
import { setLanguage } from '../actions/language';
import * as Sentry from "@sentry/react";

// Check if SENTRY_DSN is defined in the environment variables
const sentryDSN = process.env.SENTRY_DSN;

let enhancers;

if (sentryDSN) {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer({
        // Optionally pass options listed in:
        // https://docs.sentry.io/platforms/javascript/guides/react/features/redux/
    });

    enhancers = compose(
        applyMiddleware(thunk, loadingBarMiddleware()),
        sentryReduxEnhancer
    );
} else {
    enhancers = compose(
        applyMiddleware(thunk, loadingBarMiddleware())
    );
}

// TODO: redux-persist to store redux state even after page refresh

// const reduxDevTools =
//     window.__REDUX_DEVTOOLS_EXTENSION__ &&
//     window.__REDUX_DEVTOOLS_EXTENSION__();

export const store = createStore(
    reducers,
    enhancers
);

// subscribe
// store.subscribe(() => console.log(store.getState()));

// initialize
store.dispatch(setLanguage(process.env.REACT_APP_LANGUAGE || 'en'));
