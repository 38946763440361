import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Bimodel from './Bimodel';
import PageLoader from './../components/common/PageLoader';
import { setCurrentProject } from './../actions/project';
import '../styles/globals.css';

const App = () => {
    const currentProject = useSelector((state) => state.currentProject);
    setTimeout(() => {
        throw new Error("Sentry Test Error");
      });
    const dispatch = useDispatch();

    useEffect(() => {
        if (isEmpty(currentProject)) dispatch(setCurrentProject());
    });

    return (
        <BrowserRouter>
            {isEmpty(currentProject) ? <PageLoader /> : <Bimodel />}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </BrowserRouter>
    );
};

export default App;
